module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"4090173413","enableOnDevMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","i18nextOptions":{"keySeparator":false},"languages":["en","ja"],"defaultLanguage":"en","pages":[{"matchPath":"/:lang?/knowledge-automation-learn-more","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/blog/:date/:slug","getLanguageFromPath":true,"excludeLanguages":["ja"]},{"matchPath":"/:lang?/news/:date/:slug","getLanguageFromPath":true,"excludeLanguages":["en","ja"]}],"siteUrl":"https://www.amplified.ai"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"689136db47072f174bf320705ef3fb42"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
